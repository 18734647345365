import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const memberDetail = async () => {
  const requestObj = {
    path: `api/member/profile`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const afflicateChangeid = async (postData, id) => {
  const requestObj = {
    path: `app/affiliate_url_name/update/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: postData,
  };
  return invokeApi(requestObj);
};

export const ProjectInfoApi = async () => {
  const requestObj = {
    path: `api/member_init`,
    method: "GET",
    headers: {
      // "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const get_notification_setting = async () => {
  const requestObj = {
    path: `api/member/get_notification/settings`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const notification_setting = async (data) => {
  const requestObj = {
    path: `api/member/update_notification_settings`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const EditProfileApi = async (data) => {
  const requestObj = {
    path: `api/member/edit_member_profile`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const getSubscriptionsListApi = async (data) => {
  const requestObj = {
    path: `api/member/subscription_list/client`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const getSubscriptionsListApiUpdated = async (data) => {
  const requestObj = {
    path: `api/member/subscription_list/client/v1`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const cancelSubscriptionsListApi = async (id) => {
  const requestObj = {
    path: `api/member/cance/subscription/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const subscriptionsDetailApi = async (id) => {
  const requestObj = {
    path: `api/member/subscription/deatils/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
