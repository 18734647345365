import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const PodsListing = async (list_type) => {
  const requestObj = {
    path: `api/room?list_type=${list_type}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const PodsDetailApi = async (room_slug) => {
  const requestObj = {
    path: `api/room/detail/${room_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
