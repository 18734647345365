import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const source_session_list_api = async (data) => {
  const requestObj = {
    path: `api/source_session/list_source_session_active`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const source_session_recording_list_api = async (session_id, query) => {
  const requestObj = {
    path: `api/source_session_recording/source_session_recording_list_by_source_session/${session_id}?search_text=${
      query ? query : ""
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const source_session_recording_detail_api = async (recording_slug) => {
  "talha";
  const requestObj = {
    path: `api/source_session_recording/source_session_recording_detail/${recording_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
