import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const challengeListApi = async (date) => {
  const requestObj = {
    path: `api/challenge/challenge_list_for_member?start_date=${date}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const addChallengeApi = async (data) => {
  const requestObj = {
    path: `api/challenge/add_user_challenge_info`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const editChallengeApi = async (data) => {
  const requestObj = {
    path: `api/challenge/edit_user_challenge_info`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteChallengeApi = async (data) => {
  const requestObj = {
    path: `api/challenge/delete_user_challenge_info`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
