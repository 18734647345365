import {
  Accordion,
  AccordionSummary,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryCard from "src/components/NintyDay/HistoryCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { convertCurrencyToSign, dd_date_format } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { earning_history_api } from "src/DAL/90Day/NinetyDay";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import ChartJs from "src/components/NintyDay/ChartJs";
import ChartJsHistory from "src/components/NintyDay/ChartJsHistory";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function AllHistories() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const classes = useStyles();
  const { userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [memberHistories, setMemberHistories] = useState([]);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [totlePages, setTotlePages] = useState(0);
  const [contentSettings, setContentSetting] = useState({});

  const calculateTotalEarnings = (dataArray) => {
    return dataArray.reduce((total, item) => {
      return total + parseFloat(item.earning);
    }, 0);
  };

  const expandArea = (index) => {
    let temp_state = [...memberHistories];
    let temp_element = { ...temp_state[index] };
    temp_element.isExpanded = !temp_element.isExpanded;
    temp_state[index] = temp_element;
    setMemberHistories(temp_state);
  };

  const get_tracker_history = async (path) => {
    const result = await earning_history_api(path);
    if (result.code == 200) {
      setContentSetting(result.ninety_day_content_settings);
      let new_array = result.ninety_day_plans_history;
      if (state?.is_graph) {
        new_array = new_array.map((item) => ({ ...item, isExpanded: true }));
      }
      setTotlePages(result.total_page);
      setLoadMorePath(result.load_more_url);
      if (path) {
        setMemberHistories((old) => [...old, ...new_array]);
        setPageNumber((old) => old + 1);
      } else {
        setMemberHistories(new_array);
        setPageNumber(1);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    get_tracker_history(loadMorePath);
  };

  useEffect(() => {
    get_tracker_history();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  const replacedString = (item) => {
    let { start_date, target_date, completed_date } = item;
    start_date = dd_date_format(start_date);
    target_date = dd_date_format(target_date);
    completed_date = dd_date_format(completed_date);
    let string = contentSettings.tracker_history_title;
    let reslut = string.replace(/{start_date}/g, start_date);
    let new_result = reslut.replace(/{target_date}/g, target_date);
    return new_result.replace(/{completed_date}/g, completed_date);
  };

  return (
    <div className="container">
      <div className="d-flex align-items-center">
        <IconButton
          className="back-screen-button me-2"
          onClick={() => navigate("/ninety-day-tracker")}
        >
          <ArrowBackIcon />
        </IconButton>
        <div
          dangerouslySetInnerHTML={{
            __html: contentSettings?.tracker_history_page_description,
          }}
        ></div>
      </div>

      <div className="row">
        {memberHistories.length > 0 ? (
          memberHistories.map((item, index) => {
            console.log(
              item.ninety_day_tracker,
              "item.ninety_day_trackeritem.ninety_day_tracker"
            );
            return (
              <div className="col-12 mt-2">
                <Accordion expanded={item.isExpanded}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => expandArea(index)}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="svg-color"
                  >
                    <div className="d-flex justify-content-between w-100">
                      <Typography>{replacedString(item)}</Typography>
                      <Typography className="total-earning-history">
                        <span style={{ color: "#fff" }}>Target Amount: </span>
                        {`${convertCurrencyToSign(
                          userInfo.nineteen_day_plan_currency
                        )}${item.target_amount || 0}`}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <div className="px-3 pb-2">
                    <div className="d-flex justify-content-between">
                      <Typography className="theme-color">
                        {contentSettings?.history_graph_heading}
                      </Typography>
                      <div className="me-3">
                        <span style={{ color: "#fff" }}>
                          Attracted Amount:{" "}
                        </span>
                        <span style={{ color: "#f6bd4b" }}>
                          {`${convertCurrencyToSign(
                            userInfo.nineteen_day_plan_currency
                          )}${
                            calculateTotalEarnings(item.ninety_day_tracker) || 0
                          }`}
                        </span>
                      </div>
                    </div>

                    <div className="mb-3 mt-2">
                      <ChartJsHistory
                        memberEarning={item.ninety_day_tracker}
                        targetToAchieve={item.target_amount}
                        totalAmount={item.target_amount}
                        startDate={item.start_date}
                        planInfo={item}
                      />
                    </div>
                    {!state?.is_graph && item.ninety_day_tracker.length > 0 && (
                      <>
                        <div className="ms-2 mb-1">
                          <Typography className="theme-color">
                            {contentSettings?.history_earnings_heading}
                          </Typography>
                        </div>
                        <HistoryCard earnings={item.ninety_day_tracker} />
                      </>
                    )}
                  </div>
                </Accordion>
              </div>
            );
          })
        ) : (
          <div className="mt-5">
            <RecordNotFound title="History does not exists" />
          </div>
        )}
        {totlePages >= pageNumber ? (
          <div className="col-12 text-center">
            <button
              className="small-contained-button mt-3"
              onClick={loadMoreData}
              disabled={isLoadingMore}
            >
              {isLoadingMore ? "Loading..." : "Load More"}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
