import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const meditation_challenge_list_api = async (value) => {
  const requestObj = {
    path: `api/challenge/challenge_list_for_member_v1?type=${
      value ? value : "mediation"
    }`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const challenge_member_list_api = async (id, page, limit) => {
  const requestObj = {
    path: `api/challenge/list_people_doing/${id}?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const get_challange_history_by_member_api = async (data) => {
  const requestObj = {
    path: `api/member/get_challange_history_by_member`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_challange_history_by_member_meditation_api = async (
  data
) => {
  const requestObj = {
    path: `api/member/update_meditation_challange_history_by_member`,
    method: "PUT",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
