import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const live_event_detail_api = async () => {
  const requestObj = {
    path: `api/member/get_live_dynamite_event/`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const _dynamite_event_category_video_list_chat = async (video_id) => {
  const requestObj = {
    path: `api/dynamite_event_category_video_chat/v1/${video_id}?page=0&limit=50`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const _lesson_detail_list_chat = async (video_id) => {
  const requestObj = {
    path: `api/dynamite_event_category_video_chat/chat/list/lesson/${video_id}?page=0&limit=50`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const _mentorship_video_list_chat = async (video_id) => {
  const requestObj = {
    path: `api/dynamite_event_category_video_chat/chat/list/mentorship_video/${video_id}?page=0&limit=50`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const _dynamite_event_category_video_chat = async (loadMore) => {
  const requestObj = {
    path: loadMore,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
