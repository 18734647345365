import { invokeApi } from "../../bl_libs/invokeApi";
import { decryptToken } from "src/utils/constants";

export const _get_member_attitude_assessment = async () => {
  const requestObj = {
    path: `api/attitude_assessment_question/member_attitude_assessment`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const _update_attitude_assessment_result = async (data) => {
  const requestObj = {
    path: `api/attitude_assessment_question/update_attitude_assessment_result`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
