import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const _get_mentorship_category_list = async () => {
  const requestObj = {
    path: `api/mentorship_category/list_mentorship_category_active`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const _get_category_videos = async (slug) => {
  const requestObj = {
    path: `api/mentorship_category/get_category_videos/${slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
