import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const addGratitudeDailyApi = async (data) => {
  const requestObj = {
    path: `api/dynamite_gratitude/add_daily_gratitude`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateGratitudeDailyApi = async (id, data) => {
  const requestObj = {
    path: `api/dynamite_gratitude/update_gratitude/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const gratitudeDailyListApi = async () => {
  const requestObj = {
    path: `api/dynamite_gratitude/list_member_gratitude`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const gratitudeDailyListWithDate = async (data) => {
  const requestObj = {
    path: `api/dynamite_gratitude/get_gratitude_with_date_or_id_member`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteGratitudeDailyApi = async (id) => {
  const requestObj = {
    path: `api/dynamite_gratitude/delete_gratitude/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
