import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const manage_poll_answer_api = async (data) => {
  const requestObj = {
    path: `api/feeds/manage_poll_answer`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const feed_poll_details_api = async (feed_id) => {
  const requestObj = {
    path: `api/feeds/feed_poll_details/${feed_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const poll_option_user_list_api = async (data) => {
  const requestObj = {
    path: `api/feeds/poll_option_user_list`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
