import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const AddtoCArt = async (data) => {
  const requestObj = {
    path: `api/cart`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const get_shop_orders_detail = async (order_id) => {
  const requestObj = {
    path: `app/dynamite_product_order/detail/${order_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
export const UnSubscribe_and_subscribe_api = async (data) => {
  const requestObj = {
    path: `app/unsubscribe_email`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const calculate_custom_duty_api = async (data) => {
  const requestObj = {
    path: `app/calculate_custom_duty`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const tex_rate_list_api = async () => {
  const requestObj = {
    path: `api/tex_rate/active`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const PurchaseApi = async (data) => {
  const requestObj = {
    path: `app/dynamite_product_purchase_by_card/v1`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const PurchaseApiConfurm = async (data) => {
  const requestObj = {
    path: `app/confirm_dynamite_product_purchase_by_card/v1`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const removetoCArt = async (data) => {
  const requestObj = {
    path: `api/cart/remove_item_from_cart`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const removetoCArtall = async () => {
  const requestObj = {
    path: `api/cart`,
    method: "DELETE",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const dynamite_product_purchase_by_bank_api = async (data) => {
  const requestObj = {
    path: `app/dynamite_product_purchase/by_bank`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
