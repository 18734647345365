import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const meditation_categories_list = async () => {
  const requestObj = {
    path: `api/meditation_category/list_meditation_category_active`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const meditations_list = async (category_slug) => {
  const requestObj = {
    path: `api/meditation/meditation_list_by_meditation_category/${category_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const meditation_detail = async (meditation_slug) => {
  const requestObj = {
    path: `api/meditation/meditation_detail/${meditation_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};
