import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../../bl_libs/invokeApi";

export const AffirmationListApi = async (category_slug) => {
  const requestObj = {
    path: `api/affirmation/affirmation_list_by_category/${category_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const payment_plan_detail_api = async (plan_id) => {
  const requestObj = {
    path: `api/payment_plan/payment_plan_detail/metorship/${plan_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const AddAffirmationsApi = async (data) => {
  const requestObj = {
    path: `api/affirmation/`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteAffirmationsApi = async (affirmation_id) => {
  const requestObj = {
    path: `api/affirmation/${affirmation_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const EditAffirmationsApi = async (affirmation_id, data) => {
  const requestObj = {
    path: `api/affirmation/${affirmation_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
