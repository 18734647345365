import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";

export const PaymentRequestsList = async (data) => {
  const requestObj = {
    path: `api/payment_request`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const requestDetailApi = async (request_slug) => {
  const requestObj = {
    path: `api/payment_request/${request_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const get_bank_request_url_api = async (request_slug) => {
  const requestObj = {
    path: `api/payment_request/bank_request_url/get/for_client/${request_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const request_verification_Api = async (token) => {
  const requestObj = {
    path: `app/token_verification_for_payment_request`,
    method: "GET",
    headers: {
      "x-sh-auth": token,
    },
  };
  return invokeApi(requestObj);
};

export const get_site_setting = async () => {
  const requestObj = {
    path: `api/site_setting/`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const pay_now_for_subscription = async (postData) => {
  const requestObj = {
    path: `api/member/pay_now_by_member_app`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};
export const sign_agreement_api = async (data) => {
  const requestObj = {
    path: `app/sign_agreement/payment_request`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const get_intent_client_secret_for_one_time = async (postData) => {
  const requestObj = {
    path: `api/member/pay_one_time_by_member_app`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};

export const confirm_subscription_payment = async (postData) => {
  const requestObj = {
    path: `api/member/confirm_subscription_incomplete`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};

export const confirm_one_time_payment = async (postData) => {
  const requestObj = {
    path: `api/member/change_one_time_payment_status_by_member_app`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};
export const get_default_card = async (postData) => {
  const requestObj = {
    path: `app/get_customer/stripe_card`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    // postData: postData,
  };

  return invokeApi(requestObj);
};
export const send_default_card_token = async (api_key) => {
  const requestObj = {
    path: `app/get_customer/stripe_card`,
    method: "GET",
    headers: {
      // "x-sh-auth": `Basic ${base64EncodedString}`,
      Authorization: `Bearer ${api_key}`,
    },
    // postData: postData,
  };

  return invokeApi(requestObj);
};

export const update_stripe_card = async (postData) => {
  const requestObj = {
    path: `app/update/stripe_card`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};
