import { decryptToken } from "src/utils/constants";
import { invokeApi } from "../../bl_libs/invokeApi";
export const getWheelOfLifeListApi = async (data) => {
  const requestObj = {
    path: `api/member/list_sections`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateWheelOfLifeApi = async (data) => {
  const requestObj = {
    path: `api/member/update_assessment_question/v1`,
    method: "PUT",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const token_generate_for_upgrade_portal_api = async (data) => {
  const requestObj = {
    path: `api/member/token_generate_for_upgrade_portal`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
