import { decryptToken } from "src/utils/constants";
import { invokeApi } from "src/bl_libs/invokeApi";

export const bookings_list_api = async (page, limit) => {
  const requestObj = {
    path: `api/member/bookings/list?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const cancel_bookings_api = async (booking_id) => {
  const requestObj = {
    path: `api/member/cancel_booking/${booking_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
  };
  return invokeApi(requestObj);
};

export const slots_list_api = async (data) => {
  const requestObj = {
    path: `api/member/slots/list`,
    method: "POST",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const update_bookings_api = async (booking_id, data) => {
  const requestObj = {
    path: `api/member/booking/update/${booking_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": decryptToken(localStorage.getItem("token")),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
